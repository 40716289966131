'use client'

import * as TooltipPrimitives from '@radix-ui/react-tooltip'

import { Tooltip, TooltipContent, TooltipTrigger } from './ui/tooltip'

interface TooltipComponentProps
  extends Omit<TooltipPrimitives.TooltipContentProps, 'content' | 'onClick'> {
  children: React.ReactNode
  message: string | React.ReactNode
  className?: string
}

export default function TooltipComponent({
  children,
  message,
  className,
  ...props
}: TooltipComponentProps) {
  return (
    <Tooltip delayDuration={800}>
      <TooltipTrigger className={className} asChild>
        {children}
      </TooltipTrigger>
      <TooltipContent {...props} className='max-w-[19rem]'>
        {message}
      </TooltipContent>
    </Tooltip>
  )
}
