import { z } from 'zod'

export const experimentSchema = z.object({
  name: z
    .string()
    .min(1, { message: 'Name is required' })
    .max(40, { message: 'Name must be shorter than 40 characters' }),
  status: z.enum(['todo', 'in_progress', 'completed', 'canceled'], {
    required_error: 'Please select experiment status',
  }),
  priority: z.enum(['urgent', 'high', 'medium', 'low', 'no_priority'], {
    required_error: 'Please select experiment priority',
  }),
  description: z.string(),
})

const variableProps = {
  name: z.string().min(1, { message: 'Variable name required' }),
}

const variableTypeEnum = z.enum([
  'continuous',
  'binary',
  'integer',
  'categorical',
])

export const algorithmEnum = z.enum([
  'DGEMO',
  'TSEMO',
  'Usemo-EI',
  'MOEAD-EGO',
  'ParEGO',
])

export const stoppingCriterionEnum = z.enum([
  'time',
  'n_iter',
  'n_sampl',
  'hyper_val',
  'hyper_conv',
])

export const algorithmSingleEnum = z.enum(['ei', 'ucb', 'pi', 'ts', 'custom'])

const asyncStrategyEnum = z.enum([
  'none',
  'kriging_believer',
  'local_penalization',
  'believer_penalizer',
])

const binarySchema = z.object({
  type: z.literal(variableTypeEnum.enum.binary),
  name: variableProps.name,
})

const continousSchema = z
  .object({
    type: z.literal(variableTypeEnum.enum.continuous),
    name: variableProps.name,
    lowerBound: z.coerce.number(),
    upperBound: z.coerce.number(),
  })
  .refine((data) => data.lowerBound <= data.upperBound, {
    message: 'Lower bound must be smaller than upper bound',
    path: ['lowerBound'],
  })

const integerSchema = z
  .object({
    type: z.literal(variableTypeEnum.enum.integer),
    name: variableProps.name,
    lowerBound: z.coerce.number().int(),
    upperBound: z.coerce.number().int(),
  })
  .refine((data) => data.lowerBound <= data.upperBound, {
    message: 'Lower bound must be smaller than upper bound',
    path: ['lowerBound'],
  })

const categoricalSchema = z.object({
  type: z.literal(variableTypeEnum.enum.categorical),
  name: variableProps.name,
  choices: z
    .array(
      z.object({
        id: z.string(),
        text: z.string(),
      })
    )
    .min(2, { message: 'You need to add at least two choices' }),
})

const variable = categoricalSchema
  .or(integerSchema)
  .or(binarySchema)
  .or(continousSchema)
export const problemSchema = z.object({
  name: z.string().optional(),
  variables: z
    .array(variable)
    .min(2, { message: 'You need to add at least two variables' })
    .refine(
      (data) => {
        const seen = new Set()
        return data.every((obj) => {
          const value = obj.name

          if (seen.has(value)) {
            return false
          }
          seen.add(value)
          return true
        })
      },
      { message: 'Variable name must be unique', path: ['name'] }
    ),
  constraints: z
    .array(
      z
        .object({
          name: z
            .string({
              required_error: 'Constraint name required',
              description: 'Required',
            })
            .min(1, { message: 'Constraint name required' }),
        })
        .optional()
    )
    .optional(),
  objectives: z
    .array(
      z.object({
        name: z
          .string({
            required_error: 'Constraint F required',
            description: 'Required',
          })
          .min(1, { message: 'Objective name required' }),
        type: z.string().min(1),
      })
    )
    .min(1, { message: 'You need to add at least one objective' })
    .refine(
      (data) => {
        const seen = new Set()
        return data.every((obj) => {
          const value = obj.name

          if (seen.has(value)) {
            return false
          }
          seen.add(value)
          return true
        })
      },
      { message: 'Objective name must be unique', path: ['name'] }
    ),
})

export const initialValueSchema = z.object({
  name: z.number(),
  value: z.coerce.number(),
})

export const initializationSchema = z.object({
  initial_values: z
    .object({
      variables: initialValueSchema.array(),
      objectives: initialValueSchema.array(),
    })
    .array(),
})

export const initialsSchema = z.object({
  variables: z.array(z.record(z.string(), z.string())),
  objectives: z.array(z.record(z.string(), z.coerce.number())),
})

export const optimizationFormSchema = z.object({
  stopping_criterion: z
    .object({
      type: stoppingCriterionEnum.optional(),
      value: z.coerce.number(),
    })
    .optional(),
  eval_script: z.string().optional(),
})

export const optimizationSettingsSchema = z.object({
  algorithm: algorithmEnum.or(algorithmSingleEnum),
  async_strategy: asyncStrategyEnum,
  batch_size: z.number().min(1).max(20),
  par_solver_processes: z.number().min(1).default(8),
})

export const evaluationObjectivesSchema = z.object({
  objectives: z.array(z.record(z.coerce.number())),
})

export const objectiveEvaluationSchema = z.object({
  value: z.coerce.number(),
})

export type ObjEvalSchema = z.infer<typeof objectiveEvaluationSchema>
